import styled from 'styled-components'

import { colors } from '@sunburst/shared-styles'

export const FullScreenBackground = styled.div`
  align-items: center;
  background-color: ${colors.primary.medium};
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
`

export const FullScreenLogo = styled.img`
  max-width: 80%;
  max-height: 80%;
`
