import React, { FunctionComponent } from 'react'

import SEO from '@sunburst/component-seo'
import logo from '@sunburst/shared-logo/assets/images/large/sunburst-logo-yellow-white.png'

import Page from '../components/Page'
import { FullScreenBackground, FullScreenLogo } from '../styles/index.styles'

const IndexPage: FunctionComponent = () => {
  return (
    <Page>
      <FullScreenBackground>
        <SEO description="We build large, scalable, maintainable web applications that spark joy for both our clients and our users." />
        <FullScreenLogo alt="Sunburst Software Logo" src={logo} />
      </FullScreenBackground>
    </Page>
  )
}

export default IndexPage
